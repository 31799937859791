import { useContext, useState } from 'react'
import { LangContext } from '../context'
import Axios from 'axios'

const FreeLesson = ({ emailSent, setEmailSent }) => {
  const { i18n, lang } = useContext(LangContext)
  const [email, setEmail] = useState(null)

  const sendEmail = e => {
    e.preventDefault()

    Axios.post(
      'https://us-central1-calleo-dev.cloudfunctions.net/email_simple',
      { value: email }
    ).catch(error => console.log(error)).then(() => setEmailSent(true))
  }

  const handleChange = e => {
    e.preventDefault()

    setEmail(e.target.value)
  }

  return (
    <section className="free_lesson">
      <div className="container">
        <h2 className="free_lesson__title">{i18n[lang]["free_lesson"]["title_main"]}</h2>
        <p className="free_lesson__subtitle" dangerouslySetInnerHTML={{ __html: `${i18n[lang]["free_lesson"]["content"]["line1"]}` }}></p>
        <div className="free_lesson__content">
          {/* <p dangerouslySetInnerHTML={{ __html: `${i18n[lang]["free_lesson"]["content"]["line1"]}` }}></p> */}
          <p>{i18n[lang]["free_lesson"]["content"]["line2"]}</p>
          <strong>{i18n[lang]["free_lesson"]["content"]["line3"]}</strong>
        </div>
        {
          emailSent
            ? <div className="free_lesson__form hero__content__title--secondary appear">{i18n[lang]["free_lesson"]["email_input"]}</div>
            : <form action="?" className={`free_lesson__form ${emailSent ? "disappear" : ""}`} onSubmit={e => sendEmail(e)}>
              <input type="email" placeholder="tomaszkot@adres.pl" onChange={handleChange} />
              <input type="submit" className="btn btn--submit" value={i18n[lang]["free_lesson"]["button"]} />
            </form>
        }
      </div>
    </section >
  )
}

export default FreeLesson
