import React, { useContext, useState } from 'react'
import { LangContext } from '../context'
import Axios from 'axios'

const Hero = ({ emailSent, setEmailSent }) => {
  const { i18n, lang } = useContext(LangContext)
  const [email, setEmail] = useState(null)


  const sendEmail = e => {
    e.preventDefault()

    Axios.post(
      'https://us-central1-calleo-dev.cloudfunctions.net/email_simple',
      { value: email }
    ).catch(error => console.log(error)).then(() => setEmailSent(true))
  }

  const handleChange = e => {
    e.preventDefault()

    setEmail(e.target.value)
  }

  return (
    <div className="hero_wrapper">
      <div className="hero__content">
        <div className="hero__content__title--main" dangerouslySetInnerHTML={{ __html: `${i18n[lang]["hero"]["title_main"]}` }}></div>
        <div className="hero__content__title--secondary">{`${i18n[lang]["hero"]["title_sec"]}`}</div>
        {
          emailSent
            ? <div className="hero__content__title--secondary appear">{i18n[lang]["hero"]["email_input"]}</div>
            : <form action="?" className={`hero__form ${emailSent ? "disappear" : ""}`} onSubmit={(e) => sendEmail(e)}>
              <input type="email" placeholder="tomaszkot@adres.pl" onChange={handleChange} />
              <button className="btn btn--submit">{i18n[lang]["hero"]["button"]}</button>
            </form>
        }
        <div className="hero__adnotation" dangerouslySetInnerHTML={{ __html: `${i18n[lang]["hero"]["footer"]}` }}></div>
      </div>
    </div>
  )
}

export default Hero
