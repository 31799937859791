import { useContext } from 'react'
import OfferImg0 from '../images/offer/offer1.png'
import OfferImg1 from '../images/offer/offer2.png'
import OfferImg2 from '../images/offer/offer3.png'
import OfferImg3 from '../images/offer/offer4.png'
import { LangContext } from '../context'

const Offer = () => {
  const { i18n, lang } = useContext(LangContext)

  return (
    <section id="offer" className="offer">
      <div className="anti_container">
        <h1 className="section__title offer__title">{i18n[lang]["offer"]["title_main"]}</h1>
        <div className="offer__items">
          <OfferCard img={OfferImg0} order={0} />
          <OfferCard img={OfferImg1} order={1} />
          <OfferCard img={OfferImg2} order={2} />
          <OfferCard img={OfferImg3} order={3} />
        </div>
      </div>
    </section>
  )
}

export default Offer

const OfferCard = ({ img, order }) => {
  const { i18n, lang } = useContext(LangContext)
  const cardContent = i18n[lang]["offer"]["cards"][order]["content"]

  return (
    <article className="offer__item">
      <header className={`offer__item__text ${order % 2 !== 0 && "order-4"}`}>
        <h2 className="offer__item__text__title">{i18n[lang]["offer"]["cards"][order]["header"]}</h2>
        {
          cardContent.map(i => <p key={i} className="offer__item__text__description">{i}</p>)
        }
      </header>
      <picture className={`offer__item__img`}><img src={img} alt="offer item" /></picture>
    </article>
  )
}