import { useEffect, useContext, useState } from 'react'
import { collection, getDocs } from '@firebase/firestore'
import { db } from '../firebase-config'
import { LangContext } from '../context'
import logo1 from '../images/partners/1.png'
import logo2 from '../images/partners/2.png'
import logo3 from '../images/partners/3.png'
import logo4 from '../images/partners/4.png'
import logo5 from '../images/partners/5.png'
import logo6 from '../images/partners/6.png'
import logo7 from '../images/partners/7.png'
import logo8 from '../images/partners/8.png'

const Reviews = () => {
  const { i18n, lang } = useContext(LangContext)
  const [reviews, setReviews] = useState([])

  useEffect(() => {
    const getReviews = async () => {
      const data = await getDocs(collection(db, "reviews"))
      setReviews(data.docs.map(doc => ({ id: doc.id, ...doc.data() })))
    }

    getReviews()
  }, [])

  return (
    <section id="reviews" className="reviews">
      <div className="container">
        <h1 className="section__title">{i18n[lang]["reviews"]["title_main"]}</h1>
        <figure className="reviews__partners_logos">
          <img src={logo1} alt="logo1" />
          <img src={logo2} alt="logo2" />
          <img src={logo3} alt="logo3" />
          <img src={logo4} alt="logo4" />
          <img src={logo5} alt="logo5" />
          <img src={logo6} alt="logo6" />
          <img src={logo7} alt="logo7" />
          <img src={logo8} alt="logo8" />
        </figure>
        <ul className="reviews__items">
          {
            reviews.length > 0
              ? reviews.map(review => <Review key={review.id} review={review} />)
              : <pre>loading..</pre>
          }
        </ul>
      </div>
    </section>
  )
}

export default Reviews

const Review = ({ review }) => {
  return (
    <li className='reviews__item'>
      <p className="reviews__item__message">{review.content}</p>
      <footer>{review.name} {!!review.from && `| ${review.from}`}</footer>
    </li>
  )
}