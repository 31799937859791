import { useContext } from 'react'
import { useInView } from 'react-hook-inview'
import { LangContext } from '../context'
import Panorama from '../images/about/panoramix_round.png'

const About = () => {
  const { i18n, lang } = useContext(LangContext)
  const [ref, isVisible] = useInView({ threshold: 0 })

  return (
    <section id="about" className="about">
      <div className="container">
        <h1 className="section__title about__title">{i18n[lang]["about"]["title_main"]}</h1>
        <div className="about__items">
          <article>
            <h2 className="about__item__title">{i18n[lang]["about"]["bullet_points"][0]["title"]}</h2>
            <p className="about__item__text">{i18n[lang]["about"]["bullet_points"][0]["text"]}</p>
          </article>
          <article>
            <h2 className="about__item__title">{i18n[lang]["about"]["bullet_points"][1]["title"]}</h2>
            <p className="about__item__text">{i18n[lang]["about"]["bullet_points"][1]["text"]}</p>
          </article>
          <article>
            <h2 className="about__item__title">{i18n[lang]["about"]["bullet_points"][2]["title"]}</h2>
            <p className="about__item__text">{i18n[lang]["about"]["bullet_points"][2]["text"]}</p>
          </article>
          <article>
            <h2 className="about__item__title">{i18n[lang]["about"]["bullet_points"][3]["title"]}</h2>
            <p className="about__item__text">{i18n[lang]["about"]["bullet_points"][3]["text"]}</p>
          </article>
        </div>
        <img src={Panorama} alt="" className="krakow_logo" />
        <div className="about__slogan">
          {i18n[lang]["about"]["message_part1"]}
          <span ref={ref} className={isVisible ? 'bang' : ''}>{i18n[lang]["about"]["bang"]}</span>
          {i18n[lang]["about"]["message_part2"]}
        </div>
      </div>
    </section >
  )
}

export default About
