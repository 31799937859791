import { useState } from 'react'
import { CloseCircleOutlined } from '@ant-design/icons'

const SpecialBanner = ({ lang, specialOfferData }) => {
  const [bannerOn, setBannerOn] = useState(specialOfferData.on)

  return (
    <div className={`special_banner__wrapper ${bannerOn ? "" : "special_banner--closed"}`}>
      <div className="container">
        <div className="special_banner">
          <a href={specialOfferData.link} target="_blank" rel="noopener noreferrer" >
            {specialOfferData.content[lang]}
            &ensp;
            {specialOfferData.button[lang]}
          </a>
          <div className="btn special_banner__button--close" onClick={() => setBannerOn(false)}><CloseCircleOutlined /></div>
        </div>
      </div>
    </div >
  )
}

export default SpecialBanner
