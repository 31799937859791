import { collection, getDocs } from '@firebase/firestore'
import { useEffect, useState } from 'react'
import About from './components/About'
import BackToTop from './components/BackToTop'
import Contact from './components/Contact'
import Footer from './components/Footer'
import FreeLesson from './components/FreeLesson'
import Hero from './components/Hero'
import Navbar from './components/Navbar'
import Offer from './components/Offer'
import Reviews from './components/Reviews'
import Loading from './components/Loading'
import Cookies from './Cookies'
import { didUserAcknowledged } from './hooks'
import { LangContext } from './context'
import { db } from './firebase-config'

function App() {
  const [lang, setLang] = useState("en")
  const [i18n, setI18] = useState(null)
  const [emailSent, setEmailSent] = useState(false)
  const [privacyPolicyAcknowledged, setPrivacyPolicyAcknowledged] = useState(didUserAcknowledged())

  useEffect(() => {
    const getI18n = async () => {
      const data = await getDocs(collection(db, "i18n"))
      const langEN = data.docs.find(d => d.id === "en").data()
      const langPL = data.docs.find(d => d.id === "pl").data()
      setI18({ en: langEN, pl: langPL })
    }

    getI18n()
  }, [])

  return !!i18n
    ? <LangContext.Provider value={{ lang, setLang, i18n }}>
      {
        !privacyPolicyAcknowledged && <Cookies setPrivacyPolicyAcknowledged={setPrivacyPolicyAcknowledged} />
      }
      <Navbar />
      <Hero emailSent={emailSent} setEmailSent={setEmailSent} />
      <main>
        <About />
        <Offer />
        <FreeLesson emailSent={emailSent} setEmailSent={setEmailSent} />
        <Reviews />
        <Contact setEmailSent={setEmailSent} />
      </main>
      <Footer />
      <BackToTop />
    </LangContext.Provider>
    : <Loading />
}

export default App
