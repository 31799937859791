import logo from "../images/logos/logo.svg"
import { useContext, useEffect, useState } from "react"
import { LangContext } from "../context"
import { handleLangChange } from "../hooks"
import SpecialBanner from "./SpecialBanner"
import { FacebookFilled, InstagramFilled, SkypeFilled } from "@ant-design/icons"
import { collection, getDocs } from '@firebase/firestore'
import { db } from '../firebase-config'
import PL from '../images/icons/pl.svg'
import UK from '../images/icons/uk.svg'

const Navbar = () => {
  const { lang, setLang } = useContext(LangContext)
  const [specialOfferData, setOfferData] = useState({})

  useEffect(() => {
    const getOfferData = async () => {
      const data = await getDocs(collection(db, "special_offers"))
      const dataArr = data.docs.map(doc => ({ ...doc.data() }))
      const dataObj = dataArr.at(-1)
      setOfferData(dataObj)
    }

    getOfferData()
  }, [])

  return (
    <div className={`navbar_wrapper`}>
      {
        !!Object.keys(specialOfferData).length && <SpecialBanner lang={lang} specialOfferData={specialOfferData} />
      }
      <div className="container">
        <div className="navbar">
          <picture className="navbar__logo_wrapper">
            <img className="navbar__logo" src={logo} alt="Calleo Logo" />
          </picture>
          <Nav lang={lang} setLang={setLang} />
        </div>
      </div >
    </div>
  )
}

export default Navbar

const NavbarSocial = () => {
  return (
    <ul className="navbar__socials">
      <li><a href="https://facebook.com/calleosq" target="_blank" rel="noopener noreferrer"                 ><FacebookFilled /></a></li>
      <li><a href="https://instagram.com/calleo.school.of.polish" target="_blank" rel="noopener noreferrer" ><InstagramFilled /></a></li>
      <li><a href="skype:calleoschoolofpolish?call" target="_blank" rel="noopener noreferrer"               ><SkypeFilled /></a></li>
    </ul>
  )
}

const Nav = ({ lang, setLang }) => {
  const { i18n } = useContext(LangContext)

  const handleNavStatus = () => {
    const navCheckbox = document.getElementById("openSidebarMenu")
    navCheckbox.checked = false
  }

  return (
    <>
      <input type="checkbox" className="openSidebarMenu" id="openSidebarMenu" />
      <label htmlFor="openSidebarMenu" className="hamburger1">
        <div className="spinner diagonal part-1"></div>
        <div className="spinner horizontal"></div>
        <div className="spinner diagonal part-2"></div>
      </label>
      <nav id="sidebarMenu">
        <NavbarSocial />
        <ul className="sidebarMenuInner">
          <li><a
            href="#about"
            className="btn navbar__links__element hover_animation"
            onClick={handleNavStatus}
          >
            {i18n[lang]["navbar"]["menu"][0]}
          </a></li>
          <li><a
            href="#offer"
            className="btn navbar__links__element hover_animation"
            onClick={handleNavStatus}
          >
            {i18n[lang]["navbar"]["menu"][1]}
          </a></li>
          <li><a
            href="#reviews"
            className="btn navbar__links__element hover_animation"
            onClick={handleNavStatus}
          >
            {i18n[lang]["navbar"]["menu"][2]}
          </a></li>
          <li><a
            href="#contact"
            className="btn navbar__links__element hover_animation"
            onClick={handleNavStatus}
          >
            {i18n[lang]["navbar"]["menu"][3]}
          </a></li >
          <li><button
            className="btn navbar__links__element"
            onClick={() => handleLangChange(lang, setLang)}
          ><LangSwitcher lang={lang} />
          </button></li>
        </ul >
      </nav >
    </>
  )
}

const LangSwitcher = ({ lang }) => {
  if (lang === "en") return <span className="icon_field">pl&ensp;<img src={PL} alt="PL flag" /></span>
  return <span className="icon_field">en&ensp;<img src={UK} alt="UK flag" /></span>
}