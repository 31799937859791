import React, { useState } from 'react'

const Cookies = ({ setPrivacyPolicyAcknowledged }) => {
  const [more, setMore] = useState(false)

  const handleAcknowledge = () => {
    setPrivacyPolicyAcknowledged(true)
    window.localStorage.setItem('PrivacyPolicyAcknowledged', true)
  }

  const handleMore = (val) => {
    setMore(val)
  }

  return (
    <main className="cookies">
      {
        more
          ? <PrivacyPolicy />
          : <DefaultInfo />
      }
      {
        more
          ? <div className="cookies__booton_group">
            <button onClick={() => handleMore(false)} className="btn cookies_btn btn_green">OK</button>
          </div>
          : <div className="cookies__booton_group">
            <button onClick={() => handleMore(true)} className="btn cookies_btn btn_green">Więcej</button>
            <button onClick={handleAcknowledge} className="btn cookies_btn btn_red">Rozumiem</button>
          </div>
      }
    </main>
  )
}

export default Cookies

const DefaultInfo = () => {
  return <div className="cookies__content">
    <p>
      Strona korzysta z plików cookies w celu realizacji usług.
      <br />
      Używamy informacji zapisanych za pomocą plików cookies w celu zapewnienia maksymalnej wygody w korzystaniu z naszego serwisu.
      <br />
      Możesz określić warunki przechowywania lub dostępu do plików cookies w Twojej przeglądarce.
    </p>
  </div>
}

const PrivacyPolicy = () => {
  return <div className="cookies__content cookies__content--more">
    <h1>Polityka plików cookie</h1>
    <pre><i>Ostatnia aktualizacja: 15 kwietnia 2022 r.</i></pre>
    <br />
    <p>
      Niniejsza Polityka dotycząca plików cookie wyjaśnia, czym są pliki cookie i w jaki sposób z nich korzystamy. Powinieneś przeczytać tę politykę, aby zrozumieć, jakiego rodzaju plików cookie używamy lub informacje, które zbieramy za pomocą plików cookie i jak te informacje są wykorzystywane. Niniejsza Polityka Cookies została stworzona przy pomocy Generatora Polityki Cookies .
    </p>
    <br />
    <p>
      Pliki cookie zazwyczaj nie zawierają żadnych informacji umożliwiających identyfikację użytkownika, ale dane osobowe, które przechowujemy o Tobie, mogą być powiązane z informacjami przechowywanymi w plikach cookie i uzyskiwanymi z nich.Aby uzyskać więcej informacji na temat tego, w jaki sposób wykorzystujemy, przechowujemy i zabezpieczamy Twoje dane osobowe, zapoznaj się z naszą Polityką prywatności.
    </p>
    <br />
    <strong>
      Nie przechowujemy wrażliwych danych osobowych, takich jak adresy pocztowe, hasła do kont itp.w używanych przez nas plikach cookie.
    </strong>
    < h1 > Interpretacja i definicje</h1 >
    <h3>Interpretacja</h3>
    <br />
    <p>
      Słowa, których początkowa litera pisana jest wielką literą, mają znaczenie zdefiniowane w następujących warunkach.Poniższe definicje mają to samo znaczenie niezależnie od tego, czy występują w liczbie pojedynczej czy mnogiej.
    </p>
    <br />
    <h3>Definicje</h3>
    <br />
    <p>Na potrzeby niniejszej Polityki Cookies:</p>
    <br />
    <ul>
      <li>
        <strong>Firma</strong> (określana jako „Firma”, „My”, „Nas” lub „Nasz” w niniejszej Polityce plików cookie) odnosi się do Calleo.
      </li>
      <li>
        <strong>Pliki cookie</strong>  oznaczają małe pliki, które są umieszczane na Twoim komputerze, urządzeniu mobilnym lub innym urządzeniu przez witrynę internetową, zawierające szczegóły historii przeglądania tej witryny wśród wielu jej zastosowań.
      </li>
      <li>
        <strong>Witryna</strong> odnosi się do calleo.pl, dostępnej pod adresem www.calleo.pl
      </li>
      <li>
        <strong>Użytkownik</strong> oznacza osobę uzyskującą dostęp do Witryny lub korzystającą z niej, firmę lub podmiot prawny, w imieniu którego taka osoba uzyskuje dostęp do Witryny lub korzysta z niej, stosownie do przypadku.
      </li>
    </ul>
    <h1>Korzystanie z plików cookie</h1>
    <h3> Rodzaj plików cookie, których używamy</h3>
    <p>
      Pliki cookie mogą być „trwałymi” lub „sesyjnymi” plikami cookie.Trwałe pliki cookie pozostają na Twoim komputerze osobistym lub urządzeniu mobilnym, gdy przechodzisz w tryb offline, podczas gdy sesyjne pliki cookie są usuwane, gdy tylko zamkniesz przeglądarkę internetową.
    </p>
    <br />
    <p>Używamy zarówno sesyjnych, jak i trwałych plików cookie w celach określonych poniżej:</p>
    <br />
    <ul>
      <li>
        <strong>Niezbędne / niezbędne pliki cookie</strong>
        <p>Typ: sesyjne pliki cookie</p>
        <p>Administrowany przez: Nas</p>
        <p>Cel: Te pliki cookie są niezbędne do świadczenia usług dostępnych za pośrednictwem Witryny i umożliwienia korzystania z niektórych jej funkcji.Pomagają uwierzytelniać użytkowników i zapobiegać nieuczciwemu korzystaniu z kont użytkowników.Bez tych plików cookie usługi, o które prosiłeś, nie mogą być świadczone, a my używamy tych plików cookie wyłącznie w celu świadczenia tych usług.</p>
      </li>
      <br />
      <li>
        <strong>Funkcjonalne pliki cookie</strong>
        <p>Typ: trwałe pliki cookie</p>
        <p>Administrowany przez: Nas</p>
        <p>Cel: Te pliki cookie pozwalają nam zapamiętać wybory dokonywane podczas korzystania z Witryny, takie jak zapamiętanie danych logowania lub preferencji językowych.Celem tych plików cookie jest zapewnienie bardziej osobistego doświadczenia i uniknięcie konieczności ponownego wprowadzania swoich preferencji za każdym razem, gdy korzystasz z Witryny.</p>
      </li>
    </ul>
    <br />
    <h3>Twoje wybory dotyczące plików cookie</h3>
    <br />
    <p>
      Jeśli wolisz uniknąć używania plików cookie w Witrynie, najpierw musisz wyłączyć korzystanie z plików cookie w swojej przeglądarce, a następnie usunąć pliki cookie zapisane w przeglądarce powiązanej z tą witryną.W każdej chwili możesz skorzystać z tej opcji, aby uniemożliwić korzystanie z plików Cookies.
    </p>
    <br />
    <p>
      Jeśli nie akceptujesz naszych plików cookie, możesz doświadczyć pewnych niedogodności w korzystaniu z Witryny, a niektóre funkcje mogą nie działać poprawnie.
    </p>
    <p>
      Jeśli chcesz usunąć pliki cookie lub poinstruować przeglądarkę, aby usuwała lub odrzucała pliki cookie, odwiedź strony pomocy swojej przeglądarki internetowej.
    </p>
    <br />
    <ul>
      <li>
        W przypadku przeglądarki Chrome odwiedź tę stronę Google: <a href="https://support.google.com/accounts/answer/32050">support.google.com/accounts/answer/32050</a>
      </li>
      <br />
      <li>
        W przypadku przeglądarki internetowej Internet Explorer odwiedź tę stronę firmy Microsoft: <a href="http://support.microsoft.com/kb/278835">support.microsoft.com/kb/278835</a>
      </li>
      <br />
      <li>
        W przypadku przeglądarki Firefox, odwiedź tę stronę Mozilli: <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a>
      </li>
      <br />
      <li>
        W przypadku przeglądarki internetowej Safari odwiedź tę stronę firmy Apple: <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a>
      </li>
      <br />
      <li>
        W przypadku dowolnej innej przeglądarki internetowej odwiedź oficjalne strony swojej przeglądarki internetowej.
      </li>
    </ul>
    <br />
    <h1> Więcej informacji o plikach cookie</h1>
    <p>
      Możesz dowiedzieć się więcej o plikach cookie: <a href="https://pl.wikipedia.org/wiki/HTTP_cookie">Czym są pliki cookie?</a>.
    </p>
    <br />
    <h1>Skontaktuj się z nami</h1>
    Jeśli masz jakiekolwiek pytania dotyczące niniejszej Polityki plików cookie, możesz skontaktować się z nami:
    <br /><br />
    email: info@calleo.pl
  </div >
}