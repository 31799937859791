import { useState, useEffect, useContext } from "react"
import { collection, getDocs } from '@firebase/firestore'
import { db } from '../firebase-config'
import { LangContext } from "../context"
import logo from "../images/logos/logo.svg"
import {
  FacebookFilled,
  InstagramFilled,
  SkypeFilled
} from "@ant-design/icons"
import { handleLangChange, langSwitcher } from "../hooks"


const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <h2 className="footer__slogan">WE MAKE YOU SPEAK</h2>
        <main className="footer__main">
          <FooterSocial />
          <img src={logo} alt="CALLEO" className="footer__logo" />
          <Address />
        </main>
        <nav className="footer__nav">
          <FooterLinks />
        </nav>
      </div>
    </footer >
  )
}

export default Footer

const Address = () => {
  const { i18n, lang } = useContext(LangContext)
  const [info, setInfo] = useState([])

  useEffect(() => {
    const getInfo = async () => {
      const data = await getDocs(collection(db, "info"))
      setInfo(data.docs[0].data())
    }

    getInfo()
  }, [])

  return (
    <address className="footer__address">
      <p>{info["slogan"]}</p>
      <p>{info["email"]}</p>
      <p>{info["tel"]}</p>
      <br />
      <p>{i18n[lang]["footer"]["address_header"]}</p>
      <p>{i18n[lang]["footer"]["workdays"]}</p>
      <p>{info["time"]}</p>
    </address>
  )
}

const FooterLinks = () => {
  const { i18n, lang, setLang } = useContext(LangContext)

  return (
    <nav>
      <ul className="footer__nav__links">
        <li><a href="#about" >{i18n[lang]["navbar"]["menu"][0]}</a></li>
        <li><a href="#offer" >{i18n[lang]["navbar"]["menu"][1]}</a></li>
        <li><a href="#reviews" >{i18n[lang]["navbar"]["menu"][2]}</a></li>
        <li><a href="#contact" >{i18n[lang]["navbar"]["menu"][3]}</a></li>
        <li><button className="btn" onClick={() => handleLangChange(lang, setLang)}>{langSwitcher(lang)}</button></li>
      </ul>
    </nav >
  )

}

const FooterSocial = () => {
  return <aside className="footer__socials">
    <a href="https://facebook.com/calleosq" target="_blank" rel="noopener noreferrer"                 ><FacebookFilled /> Facebook</a>
    <a href="https://instagram.com/calleo.school.of.polish" target="_blank" rel="noopener noreferrer" ><InstagramFilled /> Instagram</a>
    <a href="skype:calleoschoolofpolish?call" target="_blank" rel="noopener noreferrer"               ><SkypeFilled /> Skype</a>
  </aside>
}
