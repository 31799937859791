export const langSwitcher = lang => lang === "en" ? "pl 🇵🇱" : "en 🇬🇧"
export const handleLangChange = (lang, fun) => fun(lang === "en" ? "pl" : "en")
export const didUserAcknowledged = () => {
  const valueInLocalStorage = JSON.parse(window.localStorage.getItem('PrivacyPolicyAcknowledged'))
  if (valueInLocalStorage) {
    try {
      return valueInLocalStorage
    } catch (error) {
      window.localStorage.removeItem('PrivacyPolicyAcknowledged')
    }
  }
  return false
}