import { ArrowUpOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react'

const BackToTop = () => {
  const [backToTopVisible, setBackToTopVisibility] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const visible = window.scrollY >= (window.screen.availHeight / 2)
      setBackToTopVisibility(visible)
    });
  })

  return <a href="#" className={`btn back_to_top ${backToTopVisible ? "appear" : "disappear"}`}><ArrowUpOutlined /></a>
}

export default BackToTop
