import { useContext, useState } from 'react'
import { LangContext } from '../context'
import img from '../images/contact/contact3.png'
import ReactCardFlip from 'react-card-flip'
import Axios from 'axios'

const Contact = ({ setEmailSent }) => {
  const { i18n, lang } = useContext(LangContext)
  const [ThankYouNoteOn, setThankYouNote] = useState(false)

  return (
    <section id="contact" className="contact">
      <article className="container">
        <header>
          <h1 className="section__title">{i18n[lang]["contact"]["title_main"]}</h1>
          <h2 className="section__secondary_title" dangerouslySetInnerHTML={{ __html: i18n[lang]["contact"]["title_sec"] }}></h2>
        </header>
        <div className="form_wrapper">
          <picture className="contact_img">
            <source srcSet={img} media="(min-width: 1200px)" />
            <img alt="" />
          </picture>
          <div className="flip_wrapper">
            <ReactCardFlip isFlipped={ThankYouNoteOn} flipDirection="horizontal">
              <Form setEmailSent={setEmailSent} setThankYouNote={setThankYouNote} />
              <ThankYouNote setThankYouNote={setThankYouNote} />
            </ReactCardFlip>
          </div>
        </div>
      </article>
    </section>
  )
}

export default Contact

const ThankYouNote = ({ setThankYouNote }) => {
  const { i18n, lang } = useContext(LangContext)
  return (
    <article className='thank_you_note'>
      <h3 className="thank_you_note__header">{i18n[lang]["contact"]["thank_you_note"]["header"]}</h3>
      <main className="thank_you_note__content">{i18n[lang]["contact"]["thank_you_note"]["content"]}</main>
      <footer className="thank_you_note__footer">{i18n[lang]["contact"]["thank_you_note"]["footer"]}</footer>
      <button disabled onClick={() => setThankYouNote(false)} className="btn">{i18n[lang]["contact"]["thank_you_note"]["button"]}</button>
    </article>
  )
}

const Form = ({ setEmailSent, setThankYouNote }) => {
  const { i18n, lang } = useContext(LangContext)
  const [firstname, setFirstName] = useState('')
  const [lastname, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const sendEmail = e => {
    e.preventDefault()

    Axios.post(
      'https://us-central1-calleo-dev.cloudfunctions.net/email_complete',
      { firstname, lastname, message, email }
    ).catch(error => console.log(error)).then(() => setEmailSent(true))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    sendEmail(e)
    setFirstName('')
    setLastName('')
    setEmail('')
    setMessage('')
    setThankYouNote(true)
  }

  const handleChange = e => {
    e.preventDefault()

    switch (e.target.name) {
      case 'firstname':
        setFirstName(e.target.value)
        break
      case 'lastname':
        setLastName(e.target.value)
        break
      case 'email':
        setEmail(e.target.value)
        break
      case 'message':
        setMessage(e.target.value)
        break
      default:
        break
    }
  }
  return (
    <form className="contact_form" onSubmit={e => handleSubmit(e)} >
      <h3 className="contact_form__header">{i18n[lang]["contact"]["form"]["title_main"]}</h3>
      <fieldset>
        <input
          type="firstname"
          name="firstname"
          placeholder={i18n[lang]["contact"]["form"]["first_name"]}
          value={firstname}
          onChange={handleChange} />
        <input
          type="lastname"
          name="lastname"
          placeholder={i18n[lang]["contact"]["form"]["last_name"]}
          value={lastname}
          onChange={handleChange} />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={handleChange}
          required />
        <textarea
          rows="3"
          type="message"
          name="message"
          placeholder={i18n[lang]["contact"]["form"]["message"]}
          value={message}
          onChange={handleChange} />
      </fieldset>
      <button className="btn btn--submit" type="submit" >{i18n[lang]["contact"]["form"]["button"]}</button>
    </form>
  )
}