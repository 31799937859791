import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getFunctions } from "firebase/functions"

export const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: "calleo-dev.firebaseapp.com",
  projectId: "calleo-dev",
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
})

export const functions = getFunctions(firebaseApp)
export const db = getFirestore(firebaseApp)
